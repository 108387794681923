import dynamic from 'next/dynamic'

import {
  SanityBlogAuthorPostGridModule,
  SanityBlogCategoryPostGridModule,
  SanityBlogPostGridModule,
  SanityBlogPostHeaderModule,
  SanityCartFormModule,
  SanityCollectionGridModule,
  SanityDividerPhotoModule,
  SanityGridModule,
  SanityHeroModule,
  SanityMarqueeModule,
  SanityModule,
  SanityModuleType,
  SanityReviewWidgetModule,
  SanityVideoModule,
} from '@data/sanity/queries/types/modules'
import {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { SanityCart } from '@data/sanity/queries/types/site'
import { SanityBlogPostWithoutContent } from '@data/sanity/queries/types/blog'
import { Reviews } from '@lib/review'

const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const ReviewWidget = dynamic(() => import('./review-widget'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const VideoModule = dynamic(() => import('./video'))

interface ModuleProps {
  module: SanityModule
  product?: SanityProductFragment
  posts?: SanityBlogPostWithoutContent[]
  activeVariant?: SanityProductVariantFragment
  onVariantChange?: (variantId: number) => void
  collectionProducts?: SanityProductFragment[]
  featuredProductIds?: number[]
  cartSettings?: SanityCart
  reviews?: Reviews
}

const Module = ({
  module,
  product,
  posts,
  activeVariant,
  onVariantChange,
  collectionProducts,
  featuredProductIds,
  cartSettings,
  reviews,
}: ModuleProps) => {
  switch (module._type as SanityModuleType) {
    case SanityModuleType.GRID: {
      const gridModule = module as SanityGridModule

      return (
        <Grid
          columns={gridModule.columns}
          size={gridModule.size}
          style={gridModule.style}
          noColumnGaps={gridModule.noColumnGaps}
          noRowGaps={gridModule.noRowGaps}
          reverseSequence={gridModule.reverseSequence}
        />
      )
    }

    case SanityModuleType.HERO: {
      const heroModule = module as SanityHeroModule

      return (
        <Hero
          bgType={heroModule.bgType}
          contentPosition={heroModule.contentPosition}
          content={heroModule.content}
          photos={heroModule.photos}
          muxVideo={heroModule.muxVideo}
          vimeoVideo={heroModule.vimeoVideo}
        />
      )
    }

    case SanityModuleType.DIVIDER_PHOTO: {
      const dividerPhotoModule = module as SanityDividerPhotoModule

      return <DividerPhoto photo={dividerPhotoModule.photo} />
    }

    case SanityModuleType.REVIEW_WIDGET: {
      const reviewWidgetModule = module as SanityReviewWidgetModule

      if (!reviews) {
        return null
      }

      return (
        <ReviewWidget
          reviewsStrings={reviewWidgetModule.reviewsStrings}
          type={reviewWidgetModule.type}
          product={product}
          activeVariant={activeVariant}
          reviews={reviews}
        />
      )
    }

    case SanityModuleType.PRODUCT_HERO: {
      if (!product || !activeVariant || !onVariantChange) {
        return null
      }

      return (
        <ProductHero
          product={product}
          activeVariant={activeVariant}
          onVariantChange={onVariantChange}
        />
      )
    }

    case SanityModuleType.COLLECTION_GRID: {
      const collectionGridModule = module as SanityCollectionGridModule
      const products = collectionProducts ?? []

      if (!collectionGridModule.active || products.length === 0) {
        return null
      }

      return (
        <Collection
          collectionStrings={collectionGridModule.collectionStrings}
          products={products}
          featuredProductIds={featuredProductIds ?? []}
          paginationLimit={collectionGridModule.paginationLimit}
          title={collectionGridModule.title}
          sort={collectionGridModule.sort}
          filter={collectionGridModule.filter}
        />
      )
    }

    case SanityModuleType.CART_FORM: {
      const cartFormModule = module as SanityCartFormModule

      if (!cartFormModule.active || !cartSettings) {
        return null
      }

      return <CartForm cartSettings={cartSettings} />
    }

    case SanityModuleType.VIDEO: {
      const videoModule = module as SanityVideoModule

      return (
        <VideoModule
          type={videoModule.type}
          vimeoVideo={videoModule.vimeoVideo}
          muxVideo={videoModule.muxVideo}
          settings={videoModule.settings}
          aspectRatio={videoModule.aspectRatio}
        />
      )
    }

    case SanityModuleType.MARQUEE: {
      const marqueeModule = module as SanityMarqueeModule

      return (
        <Marquee
          items={marqueeModule.items}
          speed={marqueeModule.speed ?? 0.5}
          reverse={marqueeModule.reverse}
          pausable={marqueeModule.pausable}
        />
      )
    }

    case SanityModuleType.BLOG_POST_GRID: {
      const blogPostGridModule = module as SanityBlogPostGridModule

      return (
        <BlogPostGrid
          posts={posts ?? []}
          options={blogPostGridModule.options}
        />
      )
    }

    case SanityModuleType.BLOG_POST_HEADER: {
      const blogPostHeaderModule = module as SanityBlogPostHeaderModule

      const { post, options } = blogPostHeaderModule

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case SanityModuleType.BLOG_CATEGORY_POST_GRID: {
      const blogCategoryPostGridModule =
        module as SanityBlogCategoryPostGridModule

      return (
        <BlogPostGrid
          posts={posts ?? []}
          options={blogCategoryPostGridModule.options}
        />
      )
    }

    case SanityModuleType.BLOG_AUTHOR_POST_GRID: {
      const blogAuthorPostGridModule = module as SanityBlogAuthorPostGridModule

      return (
        <BlogPostGrid
          posts={posts ?? []}
          options={blogAuthorPostGridModule.options}
        />
      )
    }
  }
}

export default Module

import { GetStaticPropsResult } from 'next'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityHomePage } from '@data/sanity/queries/types/page'
import { getShopifyDomain } from '@lib/shopify/client'
import { getPreview } from '@lib/sanity/client'
import { getHomePage } from '@lib/sanity/page'
import { Locale } from '@lib/language'
import { getReviews, Reviews } from '@lib/review'
import { CustomStaticPropsContext } from '@lib/routes'

import Layout from '@components/layout'
import Modules from '@modules/modules'

interface HomePageProps {
  locale: Locale
  page: SanityHomePage
  site: SanitySiteFragment
  reviews?: Reviews
}

const HomePage = ({ page, site, reviews }: HomePageProps) => (
  <Layout page={page} site={site} hasSmoothScroll>
    <Modules modules={page.modules} reviews={reviews} />
  </Layout>
)

export async function getStaticProps({
  locale,
  preview,
  previewData,
}: CustomStaticPropsContext): Promise<GetStaticPropsResult<HomePageProps>> {
  const homePage = await getHomePage(locale, getPreview(preview, previewData))

  if (!homePage.page) {
    return { notFound: true }
  }

  // Get Stamped.io reviews
  const shopifyDomain = getShopifyDomain(locale)
  const reviews = await getReviews(
    homePage.page.modules ?? [],
    shopifyDomain,
    homePage.site.settings.stampedApiKey
  )

  return {
    props: {
      locale,
      page: homePage.page,
      site: homePage.site,
      reviews,
    },
    revalidate: 60,
  }
}

export default HomePage
